const interetsInfo = [
    {
        id: 1,
        name: "Rétrogaming",
        icon: require('../../assets/images/icons/gaming.png')
    },
    {
        id: 2,
        name: "Histoire",
        icon: require('../../assets/images/icons/history.png')
    },
    {
        id: 3,
        name: "Les BDs",
        icon: require('../../assets/images/icons/comic.png')
    },
    {
        id: 4,
        name: "Marche à pied",
        icon: require('../../assets/images/icons/walking.png')
    },
];

export default interetsInfo;