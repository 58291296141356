const Projets = [
    {
        id: 1,
        title: "Réseau T0",
        dates: "2017 - en cours",
        description: "Site regroupant les Théâtres de l'Opprimé en France et dans les autres pays",
        lien: "https://www.reseau-to.fr/site",
        images: [
            {
                id: 1,
                src: require('../../assets/images/projets/RTo.png')
            },
            {
                id: 2,
                src: require('../../assets/images/projets/RTo2.png')
            },
        ],
        technos: [
            {
                id:1,
                title: "Wordpress",
                logo: require('../../assets/images/logos/wordpress.png')
            },
            {
                id:2,
                title: "CSS",
                logo: require('../../assets/images/logos/css.png')
            },
            {
                id:3,
                title: "PHP",
                logo: require('../../assets/images/logos/php.png')
            },
            {
                id:4,
                title: "MySQL",
                logo: require('../../assets/images/logos/mysql.png')
            }
        ]
    },
    {
        id: 2,
        title: "Rétro Série",
        dates: "2020 - 2021",
        description: "Site recensant les anciennes séries avant les années 2010",
        lien: "http://retrotv.wiremi.fr", 
        images: [
            {
                id: 1,
                src: require('../../assets/images/projets/RetroTv.png')
            },
            {
                id: 2,
                src: require('../../assets/images/projets/RetroTv1.png')
            },
        ],
        technos: [
            {
                id:1,
                title: "PHP",
                logo: require('../../assets/images/logos/php.png')
            },
            {
                id:2,
                title: "CSS",
                logo: require('../../assets/images/logos/css.png')
            },
            {
                id:3,
                title: "Bootstrap",
                logo: require('../../assets/images/logos/bootstrap.png')
            },
            {
                id: 4,
                title: "Javascript",
                logo: require('../../assets/images/logos/javascript.png')
            },
            {
                id: 5,
                title: "MySQL",
                logo: require('../../assets/images/logos/mysql.png')
            },

        ]
    },
    {
        id: 3,
        title: "Carte interactive",
        dates: "2020 - 2023",
        description: "Implantation des groupes adhérents du Réseau T0",
        lien: "https://rezoto.wiremi.fr",
        images: [
            {
                id: 1,
                src: require('../../assets/images/projets/france.png')
            },
            {
                id:2,
                src: require('../../assets/images/projets/france2.png'),
                legend: "Carte faite en 2022 sans l'aide d'un logiciel de traitement de l'image"
            },
            {
                id:3,
                src: require('../../assets/images/projets/france3.png'),
                legend: "Carte faite en 2023 avec l'aide d'un logiciel de traitement de l'image"
            }
        ],
        technos: [
            {
                id:1,
                title: "HTML",
                logo: require('../../assets/images/logos/html.png')
            },
            {
                id:2,
                title: "CSS",
                logo: require('../../assets/images/logos/css.png')
            },
            {
                id:3,
                title: "Bootstrap",
                logo: require('../../assets/images/logos/bootstrap.png')
            },
            {
                id: 4,
                title: "Javascript",
                logo: require('../../assets/images/logos/javascript.png')
            }
        ]
    },
    {
        id: 4,
        title: "Course au jouet",
        dates: "2021 - 2022",
        description: "Site e-commerce de ventes de jouets",
        images: [
            {
                id:1,
                src: require('../../assets/images/projets/caj.png')
            },
            {
                id:2,
                src: require('../../assets/images/projets/caj1.png')
            },
        ],
        technos: [
            {
                id:1,
                title: "React JS",
                logo: require('../../assets/images/logos/react.png')
            },
            {
                id:2,
                title: "Tailwind CSS",
                logo: require('../../assets/images/logos/tailwind.png')
            },
            {
                id: 3,
                title: "Node JS",
                logo: require('../../assets/images/logos/node.png')
            },
            {
                id: 4,
                title: "MongoDB",
                logo: require('../../assets/images/logos/mongodb.png')
            },

        ]
    },
    
];

export default Projets;