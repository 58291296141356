const Outils = [
    {
        id: 1,
        name: "VS Code",
        logo: require('../../assets/images/logos/vscode.png')
    },
    {
        id: 2,
        name: "Terminal",
        logo: require('../../assets/images/logos/terminal.png')
    },
    {
        id: 3,
        name: "Firefox",
        logo: require('../../assets/images/logos/firefox.png')
    },
    {
        id: 4,
        name: "Laragon",
        logo: require('../../assets/images/logos/laragon.png')
    },
    {
        id: 5,
        name: "MAMP",
        logo: require('../../assets/images/logos/mamp.png')
    },
    {
        id: 6,
        name: "FileZilla",
        logo: require('../../assets/images/logos/filezilla.png')
    },
    {
        id: 7,
        name: "Git",
        logo: require('../../assets/images/logos/git.png')
    },
    {
        id: 8,
        name: "Postman",
        logo: require('../../assets/images/logos/postman.png')
    },
    {
        id: 9,
        name: "Discord",
        logo: require('../../assets/images/logos/discord.png')
    },
]

export default Outils;